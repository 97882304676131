.App {
    display : flex;
}

.sidebar {
    display : flex;
    flex-direction: column;
}

.sidebar > nav {
    border : 2px solid black;
}