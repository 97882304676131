form {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 60%;
}

input {
    padding: 5px;
    margin: 10px;
    width: 100%;
}
