.sidebar {
    list-style: none;
}

.folder {
    border: 2px solid black;
    padding: 5px;
    margin: 5px;
    background-color: lightblue; 
 
}

.folder > a {
    text-decoration: none;
    color: black;
}


.folder > .active {
    color:purple;
}