.noteHeader {
    border: 2px solid black;
    border-radius: 4px;
    margin: 10px;
    padding: 5px;
}

.noteTitle {
    font-size: 25px;
    color: black;
    text-decoration: none;
}

.noteTitle:hover {
    cursor: pointer;
}